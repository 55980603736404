import {Web3Provider} from "../core/web3provider";
import helpers from '../core/helpers';

import logger_module from '../core/logger'
let logger = logger_module.getLogObjcect().getLogger("HeaderControlsComponentModel");

export default Backbone.Model.extend({
    defaults: {
        address: null,
    },

    async disconnectWallet()
    {
        await Web3Provider.disconnectAndForgetProvider();
        this.set({
            "address": null,
        });
        window.location.href = '/';
    },

    setAddress(address)
    {
        this.set({
            "address": address!==null?helpers.addressToShort(address):address
        });
    },


    // Метод наблюдения за изменением закэшированного адреса в провайдере. По сути бесконечно рекурсивно асинхронно!
    // вызывает сам себя, спрашивает у провайдера адрес, и если он не совпадает с адресом модели - меняет его в можели
    async watchAddressChanged()
    {
        setTimeout( ()=>{
            const model_address = this.get("address");
            const provider_address = Web3Provider.getCachedAddress();

            if (provider_address === null)
            {
                this.setAddress(null);
            }
            else if (model_address !== helpers.addressToShort(provider_address))
            {
                this.setAddress(provider_address);
                logger.debug(`Change address from ${model_address} to ${provider_address}`);
            }
            // logger.info(`model_address=${model_address} provider_address=${provider_address}`);
            this.watchAddressChanged();
        }, 500);
    },


    async initialize()
    {
        const address = Web3Provider.getCachedAddress();
        if (address)
        {
            this.setAddress(address);
        }

        //FAF
        this.watchAddressChanged();
    }
})