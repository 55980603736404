import logger_module from '../core/logger'
const logger = logger_module.getLogObjcect().getLogger("HeaderControlsComponentView");


export default Backbone.View.extend({
    el: '#header-controls-template-div',

    events: {
        "click .disconnect-wallet-btn": "onClickDisconnectWalletBtn",
    },

    initialize: async function () {
        this.listenTo(this.model, "change:address", this.render);
        await this.render();
    },

    render: async function () {
        const data = this.model.toJSON();
        const $template = $("#header-controls-template");
        const templateFunc = _.template($template.html());
        const templateHtml = templateFunc(data);
        this.$el.html(templateHtml);
    },

    onClickDisconnectWalletBtn: function (e) {
        this.model.disconnectWallet();
    }
})