import {BigNumber} from 'bignumber.js';
import {toBN, BN} from 'web3-utils';
import humanizeDuration from "humanize-duration";

import logger_module from './logger';
let logger = logger_module.getLogObjcect().getLogger("Helpers");

export default
{
    // Кусок ABI для получения типа контракта
    contract_type_abi:
    [
        {
            "inputs": [],
            "name": "getContractType",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        }
    ],

    // getStackTrace: function() {
    //     var obj = {};
    //     Error.captureStackTrace(obj, getStackTrace);
    //     return obj.stack;
    // },
    getStackTrace: function() {
        let stack;
        try {
            throw new Error('');
        }
        catch (error) {
            stack = error.stack || '';
        }

        stack = stack.split('\n').map(function (line) { return line.trim(); });
        return stack.splice(stack[0] === 'Error' ? 2 : 1);
    },


    wait: ms => new Promise(resolve => setTimeout(resolve, ms)),

    // toFixed: (x) =>
    // {
    //     if (Math.abs(x) < 1.0)
    //     {
    //         let e = parseInt(x.toString().split('e-')[1]);
    //         console.log(e);
    //         if (e)
    //         {
    //             x *= Math.pow(10, e - 1);
    //             console.log(x);
    //             x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    //             console.log(x);
    //         }
    //     }
    //     else
    //     {
    //         let e = parseInt(x.toString().split('+')[1]);
    //         if (e > 20)
    //         {
    //             e -= 20;
    //             x /= Math.pow(10, e);
    //             x += (new Array(e + 1)).join('0');
    //         }
    //     }
    //     return x;
    // },

    getFormData: function ($form)
    {
        let unindexed_array = $form.serializeArray();
        let indexed_array = {};

        _.map(unindexed_array, function (n, i)
        {
            const name = n['name'];
            const valueArr = n['value'];

            if (name.slice(-1) === ']')//if array value
            {
                let indexStr = name.split('[').pop().split(']').shift();
                let index = parseInt(indexStr, 10);
                let nameWithoutHooks = name.split('[').shift();

                if (typeof indexed_array[nameWithoutHooks] === 'undefined')
                {
                    indexed_array[nameWithoutHooks] = [];
                }
                indexed_array[nameWithoutHooks][index] = valueArr;

            }
            else
            {
                indexed_array[name] = valueArr;
            }

        });

        return indexed_array;
    },

    sendFile: function (file, success)
    {
        let data = new FormData();
        data.append("file", file);
        $.ajax({
            data: data,
            type: "POST",
            url: "/upload/image",
            cache: false,
            contentType: false,
            processData: false,
            success: success,
        });
    },


    getYoutubeId: function (url)
    {
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        let match = url.match(regExp);

        if (match && match[2].length == 11)
        {
            return match[2];
        }
        else
        {
            return 'error';
        }
    },

    download(content = {}, fileName = 'json.txt', contentType = 'text/plain') {
        let a = document.createElement("a");
        let file = new Blob([content], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    },


    copyToClipboard(elem) {
        //FIXME: не факт что работает
        // create hidden text element, if it doesn't already exist
        let targetId = "_hiddenCopyText_";
        let isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
        let origSelectionStart, origSelectionEnd;
        let target;
        if (isInput)
        {
            // can just use the original source element for the selection and copy
            target = elem;
            origSelectionStart = elem.selectionStart;
            origSelectionEnd = elem.selectionEnd;
        }
        else
        {
            // must use a temporary form element for the selection and copy
            target = document.getElementById(targetId);
            if (!target)
            {
                let target = document.createElement("textarea");
                target.style.position = "absolute";
                target.style.left = "-9999px";
                target.style.top = "0";
                target.id = targetId;
                document.body.appendChild(target);
            }
            target.textContent = elem.textContent;
        }
        // select the content
        let currentFocus = document.activeElement;
        target.focus();
        target.setSelectionRange(0, target.value.length);

        // copy the selection
        let succeed;
        try
        {
            succeed = document.execCommand("copy");
        } catch (e)
        {
            succeed = false;
        }
        // restore original focus
        if (currentFocus && typeof currentFocus.focus === "function")
        {
            currentFocus.focus();
        }

        if (isInput)
        {
            // restore prior selection
            elem.setSelectionRange(origSelectionStart, origSelectionEnd);
        }
        else
        {
            // clear temporary content
            target.textContent = "";
        }
        return succeed;
    },

    copyTextToClipboard: async function (textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    },


    goBackOrHome()
    {
        if (document.referrer.indexOf(window.location.host) !== -1)
        {
            history.back();
        } else
        {
            window.location.href = "/"
        }
    },


    key_filter: function(obj, predicate) {
        let result = {}, key;

        for (key in obj)
        {
            if (obj.hasOwnProperty(key) && predicate(key))
            {
                result[key] = obj[key];
            }
        }

        return result;
    },

    //Конверт транзакции
    sender_envelope: function (account, value, nonce)
    {
        const from_dict      = {'from': account};
        const value_dict     = typeof(value) !== "undefined" && value !== null ? {'value': value} : {};
        const nonce_dict     = typeof(nonce) !== "undefined" && nonce !== null? {'nonce': nonce} : {};

        return Object.assign
        (
            {},
            from_dict,
            value_dict,
            nonce_dict
        );
    },

    fromWei: function (number, unit)
    {
        const num = new BigNumber(number);
        switch (unit)
        {
            case "ether":
            default:
                return num.div(new BigNumber(10).pow(18));
        }
    },

    toWei: function (number, unit)
    {
        const num = new BigNumber(number);
        switch (unit)
        {
            case "ether":
            default:
                return num.multipliedBy(new BigNumber(10).pow(18));
        }
    },

    toDecimals: function (tokens_value, decimals)
    {
        return (new BigNumber(tokens_value)).multipliedBy(new BigNumber(10).pow(decimals));
    },

    fromDecimals: function (value, decimals)
    {
        return (new BigNumber(value)).div(new BigNumber(10).pow(new BigNumber(decimals)));
    },

    calcPriceInvestableValuePerValue(numerator, denominator)
    {
        return (new BigNumber(numerator)).div(new BigNumber(denominator));
    },

    calcPriceInvestableValuePerToken(numerator, denominator, decimals)
    {
        return (new BigNumber(numerator)).div(new BigNumber(denominator)).multipliedBy(toBN(10).pow(toBN(decimals)));
    },

    calcPriceInvestableTokenPerToken(numerator, denominator, decimals, investable_decimals)
    {
        return (new BigNumber(numerator)).div(new BigNumber(denominator)).multipliedBy(toBN(10).pow(toBN(decimals))).div(toBN(10).pow(toBN(investable_decimals)));

        // return this.fromDecimals(
        //     toBN(10)
        //         .pow(toBN(decimals))
        //         .mul(numerator)
        //         .div(denominator),
        //     investable_decimals);
        //vpv - value per value
        //vpt - value per token
        //tpt - token per token
    },

    toNumber: function (number)
    {
        if (typeof number === 'undefined')
        {
            // debugger;
        }

        if (typeof number.toNumber === 'undefined')
        {
            return parseFloat(number);
        }
        else
        {
            return number.toNumber();
        }
    },

    addressToShort: function (address)
    {
        return address.substring(0, 6) + "****" + address.substring(address.length-4);
    },

    // Метод получения строки интервала времени
    // (используется в html шаблонах!, поэтому среда не видит использования)
    humanizeDuration(moment_date_from, moment_date_to)
    {
        const duration_seconds = Math.abs(moment_date_from.diff(moment_date_to));
        // const duration_seconds = 24 * 3600 * 1000 + 5432000;
        let units;
        // debugger;

        if (duration_seconds <= 24 * 3600 * 1000)
        {
            //Минуты и секунды будут отображаться только если осталось меньше 24 часов
            units = ['h', 'm', 's'];
        } else if (duration_seconds <= 30* 24 * 3600 * 1000)
        {
            //Часы будут отображаться только если осталось меньше месяца
            units = ['d', 'h'];
        } else {
            //Максимальный unit - месяц
            units = ['mo', 'd'];
        }

        //Костыль для китайской локализации
        const locale = moment.locale() === 'zh-cn'? 'zh_CN' : moment.locale();

        return humanizeDuration(
            moment.duration(duration_seconds),
            {
                language: locale,
                units: units,
                round: true,
                delimiter: " "
            }
        );
    },

    // Используется для приведения числа к строке с максимальным количеством цифр после запятой (digits), при этом
    // в отличии от toFixed, в случае если крайние правые значения десятичной части являются нолями - срезает их
    toFixedWithoutTrailingZeros(number, digits=7)
    {
        digits = Number(digits)

        //Получаем десятичную часть числа
        const diff = new BigNumber(number).absoluteValue().minus(
            new BigNumber(number).absoluteValue().integerValue(BigNumber.ROUND_DOWN));
        //Подсчитывает количество знаков в десятичной части числа
        const source_decimal_digits = diff.toFixed() === "0"?0:diff.toFixed().length - 2;

        // debugger;

        //Если число имеет меньше знаков после запятой чем digits, то делаем
        //toFixed(source_decimal_digits), чтобы не получить число типа 0.0010000
        if (source_decimal_digits < digits)
        {
            return number.toFixed(source_decimal_digits);
        } else {
            //Вычисляем какое значение нужно передать в toFixed, чтобы отобразить хотя бы один значащий знак
            //дробной части числа number
            const minimum_digits_of_number = diff.toFixed().substring(2).split('').findIndex((elem) => elem !== "0") + 1;

            //Если так получилось что число у нас не ноль, а с заданным digits мы отобразим только нули,
            //используем значение minimum_digits_of_number
            if (minimum_digits_of_number > digits)
            {
                return number.toFixed(minimum_digits_of_number);
            }

            return number.toFixed(digits);
        }
    },

    // (используется в html шаблонах!, поэтому среда не видит использования)
    sprintf(str, ...argv){
      return !argv.length ? str :
          this.sprintf(str = str.replace(this.sprintf.token||"%", argv.shift()), ...argv);
    },

    copy(obj) {
        return JSON.parse(JSON.stringify(obj));
    },

    zero_eth_address: "0x0000000000000000000000000000000000000000",
};