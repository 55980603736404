import HeaderControlsComponentModel from '../model/HeaderControlsComponentModel';
import HeaderControlsComponentView from '../view/HeaderControlsComponentView';

const model = new HeaderControlsComponentModel();
const view  = new HeaderControlsComponentView({model: model});

export default {
    model,
    view
}


