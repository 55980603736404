import 'jquery-confirm';
import $ from 'jquery';
import {BigNumber} from 'bignumber.js';
import {isAddress} from 'web3-utils';
import QRCode from 'qrcode';
import helpers from "./helpers";


const is_input_value_ok = (value)=> {
    return !(
        value.isZero() ||
        value.isNaN() ||
        value.isNegative()
    );
};

export class InvestDialogs
{
    static showInvestDialog(
        blockchain_project_data,
        on_open_callback = async () => {},
        callback_approve = async () => {},
        callback_invest = async () => {},
        callback_cancel = async () => {}
    )
    {
        return new Promise((resolve, reject) => {
            const content = _.template($("#invest-dialog").html())(
                {
                    project: blockchain_project_data
                }
            );
            $.confirm({
                title: window.translations.investDialog.title,
                titleClass: 'my-popup-title',
                content: content,
                closeIcon: false,
                boxWidth: '70%',
                useBootstrap: false,
                buttons: {
                    cancel:{
                        text: window.translations.popup.cancel,
                        btnClass: 'button--cancel',
                        action: async () => {
                            await callback_cancel();
                        }
                    },
                    invest: {
                        text: window.translations.investDialog.investBtn,
                        btnClass: 'button',
                        action: async () => {
                            $("#invest-form #submit-button").click();
                        },
                        isDisabled: true,
                    },
                    approve:{
                        text: window.translations.investDialog.approveBtn,
                        btnClass: 'button',
                        action: async () => {
                            await callback_approve(
                                $("#input-investable-value").val()
                            );
                        },
                        isDisabled: false,
                        isHidden: !blockchain_project_data.contract_type.includes('erc20'),
                    },
                },

                onOpen: function ()
                {
                    const async_activator = async () => {
                        //Этот метод используется как FAF, т.к. внутри используются "долгие" методы,
                        //а пользователю необходимо показать диалоговое окно желательно сразу же, как он нажал
                        //кнопку. Поэтому данные подгружаются после.

                        // Выполняем переданный из вызывающего окна код. В нем получаются данные различные
                        //данные, связанные с балансом и allowance. Занимает время
                        if (on_open_callback !== null && !(await on_open_callback()))
                        {
                            //TODO: логер поставить
                            this.close()
                            return
                        }

                        // Включаем кнопки и интерфейс, отключаем крутилку
                        $("#invest-fieldset").attr('disabled', false);
                        $("#invest-loader").hide();

                        //Код ниже отвечает за введенные данные и изменения интерфейса в зависимости от них
                        let investable_tokens_in_tokens;
                        let investable_tokens_in_value;
                        let project_tokens_in_tokens;
                        let project_tokens_in_value;
                        const invest_dialog = this;

                        //---------------Методы для различных проверок
                        const check_and_show_not_enough_balance = ()=>{
                            //Проверяем что баланс позволяет купить сколько хотим.
                            //Если что-то не так - дальше ничего не проверяем
                            if (investable_tokens_in_value.isGreaterThan(blockchain_project_data.investable_balance))
                            {
                                $("#invest-dialog-popup-content-info-red").text(
                                    helpers.sprintf(
                                        window.translations.investDialog.balanceLessErrorMsg,
                                        blockchain_project_data.investable_balance_formatted,
                                        blockchain_project_data.investable_symbol,
                                    )
                                ).show()
                                return false;
                            }
                            return true;
                        };

                        const check_available_tokens_in_limited_project = ()=> {
                            //Показываем сообщение об ошибке когда пользователь хочет купить больше чем есть
                            if (
                                blockchain_project_data.is_limited &&
                                blockchain_project_data.phases[blockchain_project_data.current_phase_idx].available_to_sell.isLessThan(
                                    project_tokens_in_value
                                )
                            ) {
                                $("#invest-dialog-popup-content-info-green").hide();
                                $("#invest-dialog-popup-content-info-red").text(
                                    helpers.sprintf(
                                        window.translations.investDialog.availableLessErrorMsg,
                                        blockchain_project_data.phases[blockchain_project_data.current_phase_idx].available_to_sell_formatted,
                                        blockchain_project_data.symbol,
                                    )
                                ).show()
                                return false;
                            }
                            return true;
                        }


                        //Метод проверки введенных значений и изменения из-за этого UI
                        const check_values_and_enable_od_disable_invest_button = () => {
                            //Прячем все и выключаем все в UI
                            $("#invest-dialog-popup-content-info-green").hide();
                            $("#invest-dialog-popup-content-info-red").hide();
                            this.buttons.invest.disable();


                            //Две ветки выполнения. ERC20 и ETH
                            if (blockchain_project_data.contract_type.includes('erc20')) {
                                this.buttons.approve.enable();

                                //Проверка количества разрешенного
                                if (blockchain_project_data.allowance.isZero())
                                {
                                    //Если разрешено 0
                                    $("#invest-dialog-popup-content-info-red").text(
                                        helpers.sprintf(
                                            window.translations.investDialog.approveIfZero,
                                            blockchain_project_data.investable_symbol,
                                        )).show()
                                } else {
                                    //Если что-то разрешено
                                    $("#invest-dialog-popup-content-info-green").text(
                                        helpers.sprintf(
                                            window.translations.investDialog.approved,
                                            blockchain_project_data.allowance_formatted,
                                            blockchain_project_data.investable_symbol,
                                        )
                                    ).show()
                                }

                                //Проверка введенного значения
                                if (!is_input_value_ok(investable_tokens_in_value))
                                {
                                    return
                                }

                                //Проверка баланса
                                if (!check_and_show_not_enough_balance())
                                {
                                    return;
                                }

                                //Проверка количества продаваемых токенов а limited проекте
                                if (!check_available_tokens_in_limited_project())
                                {
                                    return;
                                }

                                // Проверка, что мы не хотим отправить больше чем allowance
                                if (investable_tokens_in_value.isGreaterThan(blockchain_project_data.allowance))
                                {
                                    $("#invest-dialog-popup-content-info-green").hide();
                                    $("#invest-dialog-popup-content-info-red").text(
                                        helpers.sprintf(
                                            window.translations.investDialog.allowanceLessErrorMsg,
                                            blockchain_project_data.allowance_formatted,
                                            blockchain_project_data.investable_symbol,
                                        )
                                    ).show()
                                    return;
                                }
                            } else
                            {
                                //Проверка введенного значения
                                if (!is_input_value_ok(investable_tokens_in_value))
                                {
                                    return
                                }

                                //Проверка баланса
                                if (!check_and_show_not_enough_balance())
                                {
                                    return;
                                }

                                //Проверка количества продаваемых токенов а limited проекте
                                if (!check_available_tokens_in_limited_project())
                                {
                                    return;
                                }
                            }
                            this.buttons.invest.enable();
                        }
                        //################Методы для различных проверок


                        //--------- Подключаем обработчики
                        // редактирование количества investable
                        $("#input-investable-value")
                            .off('keyup keypress')
                            .on('keyup keypress', (e) => {
                                //Если это нажатие на Enter - игнорируем. Т.к. если этого не сделать,
                                //то произойдет submit формы и все сломается
                                if (e.type === "keypress" && e.keyCode === 13) {
                                    e.preventDefault();
                                    return false;
                                }

                                const $el = $(e.target);
                                investable_tokens_in_tokens = new BigNumber($el.val());
                                investable_tokens_in_value = helpers.toDecimals(investable_tokens_in_tokens, blockchain_project_data.investable_decimals);

                                //Если ввели неправильные данные - ничего не отображаем и не пересчитываем
                                if (!is_input_value_ok(investable_tokens_in_value))
                                {
                                    $("#input-token-value").val("");
                                    return;
                                }

                                project_tokens_in_value = investable_tokens_in_value.div(
                                    blockchain_project_data.current_price_for_value_in_investable_value);
                                project_tokens_in_tokens = helpers.fromDecimals(project_tokens_in_value, blockchain_project_data.decimals);

                                $("#input-token-value").val(
                                    helpers.toFixedWithoutTrailingZeros(project_tokens_in_tokens, blockchain_project_data.decimals)
                                );

                                check_values_and_enable_od_disable_invest_button();
                            });

                        // редактирование количества токенов проекта
                        $("#input-token-value")
                            .off('keyup keypress')
                            .on('keyup keypress', (e) => {
                                //Если это нажатие на Enter - игнорируем. Т.к. если этого не сделать,
                                //то произойдет submit формы и все сломается
                                if (e.type === "keypress" && e.keyCode === 13) {
                                    e.preventDefault();
                                    return false;
                                }

                                const $el = $(e.target);
                                project_tokens_in_tokens = new BigNumber($el.val());
                                project_tokens_in_value = helpers.toDecimals(project_tokens_in_tokens, blockchain_project_data.decimals);

                                //Если ввели неправильные данные - ничего не отображаем и не пересчитываем
                                if(!is_input_value_ok(project_tokens_in_value))
                                {
                                    $("#input-investable-value").val("");
                                    return;
                                }

                                investable_tokens_in_value = project_tokens_in_value.multipliedBy(
                                    blockchain_project_data.current_price_for_value_in_investable_value
                                )
                                investable_tokens_in_tokens = helpers.fromDecimals(investable_tokens_in_value, blockchain_project_data.investable_decimals);

                                $("#input-investable-value").val(
                                    helpers.toFixedWithoutTrailingZeros(investable_tokens_in_tokens, blockchain_project_data.investable_decimals)
                                );

                                check_values_and_enable_od_disable_invest_button();
                            }
                        );

                        // нажатие кнопки invest/buy tokens
                        $("#invest-form").off('submit').on('submit', async (e) =>
                        {
                            //Дополнительная проверка, чтобы не передали 0 в investable_tokens_in_value
                            if (!is_input_value_ok(investable_tokens_in_value))
                            {
                                return
                            }

                            invest_dialog.close();
                            await callback_invest(investable_tokens_in_value);
                        });

                        //Если это ERC20 и есть allowance - подставляем его в investable_value
                        if (
                            blockchain_project_data.contract_type.includes('erc20') &&
                            !blockchain_project_data.allowance.isZero()
                        )
                        {
                            $("#input-investable-value").val(blockchain_project_data.allowance_formatted);
                            $("#input-investable-value").keyup();
                        }
                    }

                    //FAF. Запускаем так, чтобы пользователь сразу увидел окно. Когда данные
                    //подгрузятся - интерфейс обновится. Исчезнет крутилка итд
                    async_activator()
                }
            });
        });

    }
}

export class AddProfitDialogs
{
    static showAddProfitDialog(
        blockchain_project_data,
        on_open_callback = null,
        callback_approve = async (to_approve_value) => {},
        callback_add_profit = async () => {},
        callback_cancel = async () => {},
        callback_error = null
    )
    {
        return new Promise((resolve, reject) => {
            const content = _.template($("#add-profit-dialog").html())(
                {
                    project: blockchain_project_data
                }
            );
            $.confirm({
                title: window.translations.addProfitDialog.title,
                // keyboardEnabled: false,
                titleClass: 'my-popup-title',
                content: content,
                closeIcon: false,
                boxWidth: '70%',
                useBootstrap: false,
                buttons: {
                    cancel:{
                        text: window.translations.popup.cancel,
                        btnClass: 'button--cancel',
                        action: async () => {
                            await callback_cancel();
                        }
                    },
                    addProfit: {
                        text: window.translations.addProfitDialog.addProfitBtn,
                        btnClass: 'button',
                        action: async () => {
                            $("#add-profit-form #submit-button").click();
                        },
                        isDisabled: true,
                    },
                    approve:{
                        text: window.translations.addProfitDialog.approveBtn,
                        btnClass: 'button',
                        action: async () => {
                            await callback_approve(
                                $("#input-investable-value").val()
                            );
                        },
                        isDisabled: false,
                        isHidden: !blockchain_project_data.contract_type.includes('erc20'),
                    },
                },

                onOpen: function ()
                {
                    const async_activator = async () => {
                        //Этот метод используется как FAF, т.к. внутри используются "долгие" методы,
                        //а пользователю необходимо показать диалоговое окно желательно сразу же, как он нажал
                        //кнопку. Поэтому данные подгружаются после.

                        // Выполняем переданный из вызывающего окна код. В нем получаются данные различные
                        //данные, связанные с балансом и allowance. Занимает время
                        if (on_open_callback !== null && !(await on_open_callback()))
                        {
                            //TODO: логер поставить
                            this.close()
                            return
                        }

                        // Включаем кнопки и интерфейс, отключаем крутилку
                        $("#add-profit-fieldset").attr('disabled', false);
                        $("#add-profit-loader").hide();


                        //Код ниже отвечает за введенные данные и изменения интерфейса в зависимости от них
                        let profit_tokens_in_tokens;
                        let profit_tokens_in_value;
                        const add_profit_dialog = this;

                        const check_and_show_not_enough_balance = ()=>{
                            //Проверяем что баланс позволяет купить сколько хотим.
                            //Если что-то не так - дальше ничего не проверяем
                            if (profit_tokens_in_value.isGreaterThan(blockchain_project_data.investable_balance))
                            {
                                $("#add-profit-dialog-popup-content-info-red").text(
                                    helpers.sprintf(
                                        window.translations.addProfitDialog.balanceLessErrorMsg,
                                        blockchain_project_data.investable_balance_formatted,
                                        blockchain_project_data.investable_symbol,
                                    )
                                ).show()
                                return false;
                            }
                            return true;
                        };

                        const check_investable_less_or_equal_allowance = () => {
                            if (profit_tokens_in_value.isGreaterThan(blockchain_project_data.allowance))
                            {
                                $("#add-profit-dialog-popup-content-info-green").hide();
                                $("#add-profit-dialog-popup-content-info-red").text(
                                    helpers.sprintf(
                                        window.translations.addProfitDialog.allowanceLessErrorMsg,
                                        blockchain_project_data.allowance_formatted,
                                        blockchain_project_data.investable_symbol,
                                    )
                                ).show()
                                return false;
                            }
                            return true;
                        }


                        //Метод проверки введенных значений и изменения из-за этого UI
                        //Вызывается при изменении значения в
                        const check_values_and_enable_or_disable_add_profit_button = () => {
                            //Прячем все и выключаем все в UI
                            $("#add-profit-dialog-popup-content-info-green").hide();
                            $("#add-profit-dialog-popup-content-info-red").hide();
                            this.buttons.addProfit.disable();

                            //Две ветки выполнения. ERC20 и ETH
                            if (blockchain_project_data.contract_type.includes('erc20')) {
                                this.buttons.approve.enable();

                                //Проверка количества разрешенного
                                if (blockchain_project_data.allowance.isZero())
                                {
                                    //Если разрешено 0
                                    $("#add-profit-dialog-popup-content-info-red").text(
                                        helpers.sprintf(
                                            window.translations.addProfitDialog.approveIfZero,
                                            blockchain_project_data.investable_symbol,
                                        )).show()
                                } else {
                                    //Если что-то разрешено
                                    $("#add-profit-dialog-popup-content-info-green").text(
                                        helpers.sprintf(
                                            window.translations.addProfitDialog.approved,
                                            blockchain_project_data.allowance_formatted,
                                            blockchain_project_data.investable_symbol,
                                        )
                                    ).show()
                                }

                                //Проверка введенного значения
                                if (!is_input_value_ok(profit_tokens_in_value))
                                {
                                    return
                                }

                                //Проверка баланса
                                if (!check_and_show_not_enough_balance())
                                {
                                    return;
                                }

                                // Проверка, что мы не хотим отправить больше чем allowance
                                if (!check_investable_less_or_equal_allowance())
                                {
                                    return;
                                }
                            } else
                            {
                                //Проверка введенного значения
                                if (!is_input_value_ok(profit_tokens_in_value))
                                {
                                    return
                                }

                                //Проверка баланса
                                if (!check_and_show_not_enough_balance())
                                {
                                    return;
                                }
                            }
                            this.buttons.addProfit.enable();
                        }

                        //--------- Подключаем обработчики
                        // редактирование количества investable
                        $("#input-investable-value")
                            .off('keyup keypress')
                            .on('keyup keypress', (e) => {
                                //Если это нажатие на Enter - игнорируем. Т.к. если этого не сделать,
                                //то произойдет submit формы и все сломается
                                if (e.type === "keypress" && e.keyCode === 13) {
                                    e.preventDefault();
                                    return false;
                                }

                                const $el = $(e.target);
                                profit_tokens_in_tokens = new BigNumber($el.val());
                                profit_tokens_in_value = helpers.toDecimals(
                                    profit_tokens_in_tokens,
                                    blockchain_project_data.investable_decimals);

                                check_values_and_enable_or_disable_add_profit_button();
                            });


                        // нажатие кнопки invest/buy tokens
                        $("#add-profit-form").off('submit').on('submit', async (e) =>
                        {
                            //Дополнительная проверка, чтобы не передали 0 в profit_tokens_in_value
                            if (!is_input_value_ok(profit_tokens_in_value))
                            {
                                return
                            }

                            add_profit_dialog.close();
                            await callback_add_profit(profit_tokens_in_value);
                        });
                        //######### Подключаем обработчики

                        //Если это ERC20 и есть allowance - подставляем его в investable_value
                        if (
                            blockchain_project_data.contract_type.includes('erc20') &&
                            !blockchain_project_data.allowance.isZero()
                        )
                        {
                            $("#input-investable-value").val(blockchain_project_data.allowance_formatted);
                            $("#input-investable-value").keyup();
                        }
                    }

                    //FAF. Запускаем так, чтобы пользователь сразу увидел окно. Когда данные
                    //подгрузятся - интерфейс обновится. Исчезнет крутилка итд
                    async_activator()
                }
            });
        });
    }
}

export class ProviderDialogs
{
    static showExposeMetamaskAccountsDialog(
        callback_ok = async () => {},
        callback_select_provider = async () => {},
        callback_back = async () => {})
    {
        const content = $("#expose-accounts-dialog").html();
        return new Promise((resolve, reject) => {
            $.alert({
                title: false,
                content: content,
                boxWidth: '70%',
                useBootstrap: false,
                animation: 'none',
                buttons: {
                    back: {
                        text: window.translations.popup.back,
                        btnClass: 'button dialog__button-back',
                        action: async () => {
                            await callback_back();
                            resolve();
                        }
                    },
                    selectProvider: {
                        text: translations.providerDialogMessages.selectProviderButton,
                        btnClass: 'button dialog__button-select-provider',
                        action: async () => {
                            await callback_select_provider();
                            resolve();
                        }
                    },
                    ok: {
                        text: window.translations.popup.ok,
                        btnClass: 'button dialog__button-ok',
                        action: async () => {
                            await callback_ok();
                            resolve()
                        }
                    }
                }
            });
        });
    }

    static showInstallMetamaskDialog(
        callback_ok = async () => {},
        callback_select_provider = async () => {}
    )
    {
        return new Promise((resolve, reject) => {
            const content = $("#install-metamask-dialog").html();
            $.alert({
                title: false,
                content: content,
                boxWidth: '70%',
                useBootstrap: false,
                animation: 'none',
                buttons: {
                    selectProvider: {
                        text: translations.providerDialogMessages.selectProviderButton,
                        btnClass: 'button dialog__button-select-provider',
                        action: async () => {
                            await callback_select_provider();
                            resolve();
                        }
                    },
                    installMetamask: {
                        text: translations.providerDialogMessages.installMetamaskButton,
                        btnClass: 'button button--no-border',
                        action: () => {
                            if (new RegExp("Firefox").test(window.navigator.userAgent)) {
                                window.open(
                                    "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/",
                                    "_blank");
                            } else if (new RegExp("Chrome").test(window.navigator.userAgent)) {
                                window.open(
                                    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                                    "_blank");
                            } else {
                                window.open("https://metamask.io/", "_blank");
                            }
                            return false;
                        }
                    },
                    ok: {
                        text: window.translations.popup.ok,
                        btnClass: 'button dialog__button-ok',
                        action: async () => {
                            await callback_ok();
                            resolve()
                        }
                    }
                }
            });
        });
    }

    static showWarnMetamaskDisconnectDialog() {
        const content = _.template($("#disconnect-metamask-warning-dialog").html())();
        return new Promise((resolve, reject) => {
            $.alert({
                title: false,
                content: content,
                boxWidth: '70%',
                useBootstrap: false,
                animation: 'none',
                buttons: {
                    ok: {
                        text: window.translations.popup.ok,
                        btnClass: 'button dialog__button-ok',
                        action: () => {
                            resolve()
                        }
                    }
                }
            });
        });
    }

    static #showChangeNetworkBase(
        callback_ok = async () => {},
        callback_back = async () => {},
        selector)
    {
        const content = _.template($(selector).html())();
        return new Promise((resolve, reject) => {
            $.alert({
                title: false,
                content: content,
                boxWidth: '70%',
                useBootstrap: false,
                animation: 'none',
                buttons: {
                    back: {
                        text: window.translations.popup.back,
                        btnClass: 'button dialog__button-back',
                        action: async () => {
                            await callback_back();
                            resolve()
                        }
                    },
                    ok: {
                        text: window.translations.popup.ok,
                        btnClass: 'button dialog__button-ok',
                        action: async () => {
                            await callback_ok();
                            resolve()
                        }
                    }
                }
            });
        });
    }

    static showChangeNetworkMetamaskDialog(
        callback_ok = async () => {},
        callback_back = async () => {})
    {
        const selector = "#change-network-metamask-dialog";
        return this.#showChangeNetworkBase(
            callback_ok,
            callback_back,
            selector
        )
    }

    static showChangeNetworkWalletconnectDialog(
        callback_ok = async () => {},
        callback_back = async () => {})
    {
        const selector = "#change-network-walletconnect-dialog";
        return this.#showChangeNetworkBase(
            callback_ok,
            callback_back,
            selector
        )
    }

    static #showChangeAccountBase(
        callback_ok = async () => {},
        callback_back = async () => {},
        callback_select_provider = async () => {},
        requested_account,
        current_account,
        selector)
    {
        const content = _.template($(selector).html())({requested_account, current_account});
        return new Promise((resolve, reject) => {
            $.alert({
                title: false,
                content: content,
                boxWidth: '70%',
                useBootstrap: false,
                animation: 'none',
                buttons: {
                    selectProvider: {
                        text: translations.providerDialogMessages.selectProviderButton,
                        btnClass: 'button dialog__button-select-provider',
                        action: async () => {
                            await callback_select_provider();
                            resolve();
                        }
                    },
                    back: {
                        text: window.translations.popup.back,
                        btnClass: 'button dialog__button-back',
                        action: async () => {
                            await callback_back();
                            resolve()
                        }
                    },
                    ok: {
                        text: window.translations.popup.ok,
                        btnClass: 'button dialog__button-ok',
                        action: async () => {
                            await callback_ok();
                            resolve()
                        }
                    }
                }
            });
        });
    }


    static showChangeAccountMetamaskDialog(
        requested_account,
        current_account,
        callback_ok = async () => {},
        callback_back = async () => {},
        callback_select_provider = async () => {}
    )
    {
        const selector = "#change-account-metamask-dialog";
        return this.#showChangeAccountBase(
            callback_ok,
            callback_back,
            callback_select_provider,
            requested_account,
            current_account,
            selector
        )
    }

    static showChangeAccountWalletconnectDialog(
        requested_account,
        current_account,
        callback_ok = async () => {},
        callback_back = async () => {},
        callback_select_provider = async () => {},
        )
    {
        const selector = "#change-account-walletconnect-dialog";
        return this.#showChangeAccountBase(
            callback_ok,
            callback_back,
            callback_select_provider,
            requested_account,
            current_account,
            selector
        )
    }


    static showSelectProviderDialog(
        callback_ok = async () => {},
        callback_back = async () => {}) {
        return new Promise((resolve, reject) => {
            const content = $("#select-provider-dialog").html();
            $.alert({
                title: false,
                content: content,
                boxWidth: '70%',
                useBootstrap: false,
                animation: 'none',
                buttons: {
                    back: {
                        text: window.translations.popup.back,
                        btnClass: 'button dialog__button-back',
                        action: async () => {
                            await callback_back();
                            resolve()
                        }
                    },
                    ok: {
                        text: window.translations.popup.ok,
                        btnClass: 'button dialog__button-ok',
                        action: async () => {
                            await callback_ok();
                            resolve()
                        }
                    }
                }
            });
        });
    }
}

export class SendReceiveDialogs
{
    static showSendDialog(
        token_data,
        callback_send = async () => {},
        callback_cancel = async () => {})
    {
        const content = _.template($("#send-dialog").html())(
            {
                token_data: token_data
            }
        );

        return new Promise((resolve, reject) => {
            $.alert({
                title: window.translations.sendDialog.title,
                titleClass: 'my-popup-title',
                content: content,
                boxWidth: '70%',
                useBootstrap: false,
                animation: 'none',
                buttons: {
                    cancel:{
                        text: window.translations.popup.cancel,
                        btnClass: 'button--cancel',
                        action: async () => {
                            await callback_cancel();
                            resolve();
                        }
                    },
                    send: {
                        text: window.translations.sendDialog.sendBtn,
                        btnClass: 'button dialog__button-ok',
                        action: () => {
                            $("#send-form #submit-button").click();
                            return false;
                        }
                    }
                },
                onOpen: function ()
                {
                    const send_dialog = this;
                    let to_send_in_value;
                    let receiver_address = "";


                    //Метод проверки введенных значений и изменения из-за этого UI
                    const check_values_and_show_error = () => {
                        let is_ok = true;

                        //Проверка баланса
                        if (token_data.balance.isLessThan(new BigNumber(to_send_in_value)))
                        {
                            $("#balance-error-info").show();
                            $("#submit-button").prop('disabled', true);
                            is_ok = false;
                        } else {
                            $("#balance-error-info").hide();
                        }

                        //Проверка адреса получателя
                        if (receiver_address.length > 0 && !isAddress(receiver_address))
                        {
                            $("#address-error-info").show();
                            $("#submit-button").prop('disabled', true);
                            is_ok = false;
                        } else
                        {
                            $("#address-error-info").hide();
                        }

                        //Если все хорошо, активируем кнопку submit
                        if (is_ok)
                        {
                            $("#submit-button").prop('disabled', false);
                        }
                    }


                    // редактирование количества
                    $("#input-send-value")
                        .off('keyup')
                        .on('keyup', (e) => {
                            const $el = $(e.target);
                            to_send_in_value =  helpers.toDecimals(BigNumber($el.val()), token_data.decimals);

                            check_values_and_show_error();
                        });

                    // редактирование адреса
                    $("#input-send-address")
                        .off('keyup')
                        .on('keyup', (e) => {
                            const $el = $(e.target)
                            receiver_address = $el.val();

                            check_values_and_show_error();
                        });

                    // нажатие кнопки invest/buy tokens
                    $("#send-form").off('submit').on('submit', async (e) =>
                    {
                        //Дополнительная проверка, чтобы все данные были переданы
                        if (to_send_in_value.isZero() || receiver_address.length === 0)
                        {
                            return
                        }

                        send_dialog.close();
                        await callback_send(receiver_address, to_send_in_value);
                    });
                }
            });
        });
    }

    static showReceiveDialog(
        address,
        callback_ok = async () => {}
    )
    {
        const content = _.template($("#receive-dialog").html())(
            {
                address: address
            }
        );

        return new Promise((resolve, reject) => {
            $.alert({
                title: window.translations.receiveDialog.title,
                titleClass: 'my-popup-title',
                content: content,
                boxWidth: '70%',
                useBootstrap: false,
                animation: 'none',
                buttons: {
                    calc: {
                        text: window.translations.receiveDialog.calc,
                        btnClass: 'button dialog__button-ok',
                        action:() => {
                            window.open(
                                "/calc-cnr",
                                "_blank");
                            return false;
                        }
                    },
                    copy: {
                        text: window.translations.receiveDialog.copy,
                        btnClass: 'button dialog__button-ok',
                        action: async () => {
                            await helpers.copyTextToClipboard(address);
                            //TODO: сказать что данные скопированы
                            resolve()
                        }
                    },
                    ok: {
                        text: window.translations.popup.ok,
                        btnClass: 'button dialog__button-ok',
                        action: async () => {
                            await callback_ok();
                            resolve()
                        }
                    },
                },
                onOpen: function ()
                {
                    const canvas = document.getElementById('receive-dialog-qr-canvas');
                    QRCode.toCanvas(canvas, address, {margin: 0, scale: 8}, function (error) {
                        if (error) {
                            console.error("Error showing QRCode")
                        }
                    })
                }
            });
        });
    }

    static showApprovedDialog(
        approve_data,
        on_open_callback = async () => {},
        callback_approve = async () => {},
        callback_cancel = async () => {})
    {
        const content = _.template($("#approve-dialog").html())(
            {
                approve_data: approve_data
                /*
                approve_data нужно собирать руками. Должна иметь поля
                symbol, decimals, spender, balance, balance_formatted, allowance, allowance_formatted
                опционально: to_approve_value
                 */
            }
        );


        return new Promise((resolve, reject) => {
            $.alert({
                title: window.translations.approveDialog.title,
                titleClass: 'my-popup-title',
                content: content,
                boxWidth: '70%',
                useBootstrap: false,
                animation: 'none',
                buttons: {
                    cancel:{
                        text: window.translations.popup.cancel,
                        btnClass: 'button--cancel',
                        action: async () => {
                            await callback_cancel();
                            resolve(false);
                        }
                    },
                    approve: {
                        text: window.translations.approveDialog.approveBtn,
                        btnClass: 'button dialog__button-ok',
                        action: async () => {
                            $("#send-form #submit-button").click();
                            const approved_in_tokens = $("#approve-dialog-input-approve-value").val();
                            const approved_in_value = helpers.toDecimals(approved_in_tokens, approve_data.decimals);
                            await callback_approve(approved_in_value);
                            resolve(true);
                        },
                        isDisabled: true
                    }
                },
                onOpen: function ()
                {
                    const async_activator = async () => {
                        //Этот метод используется как FAF, т.к. внутри используются "долгие" методы,
                        //а пользователю необходимо показать диалоговое окно желательно сразу же, как он нажал
                        //кнопку. Поэтому данные подгружаются после.

                        // Выполняем переданный из вызывающего окна код. В нем получаются данные различные
                        //данные, связанные с балансом и allowance. Занимает время
                        if (on_open_callback && !await on_open_callback())
                        {
                            this.close()
                            return
                        }

                        //Если уже есть что-то approved - отображаем
                        if (!approve_data.allowance.isZero())
                        {
                            $("#approve-dialog-popup-content-info-green").text(
                                helpers.sprintf(
                                    window.translations.approveDialog.alreadyApprovedMsg,
                                    approve_data.allowance_formatted,
                                    approve_data.symbol,
                                    approve_data.spender,
                                    approve_data.symbol,
                                )).show()
                        }

                        $("#approve-dialog-input-approve-value")
                            .off('keyup keypress')
                            .on('keyup keypress', (e) => {
                                //Если это нажатие на Enter - игнорируем. Т.к. если этого не сделать,
                                //то произойдет submit формы и все сломается
                                if (e.type === "keypress" && e.keyCode === 13) {
                                    e.preventDefault();
                                    return false;
                                }

                                //Всегда выключаем кнопку Approve в начале
                                this.buttons.approve.disable();
                                const $el = $(e.target);
                                if ($el.val() === "")
                                {
                                    //Если в поле ничего не введено, возвращаем, оставляя выключенной кнопку Approve
                                    return;
                                }
                                const to_approve_in_tokens = new BigNumber($el.val());
                                const to_approve_in_value = helpers.toDecimals(to_approve_in_tokens, approve_data.decimals);

                                //Если ввели неправильные данные. На 0 не проверяем специально,
                                //чтобы можно было отключить allowance
                                if (to_approve_in_value.isNaN() ||
                                    to_approve_in_value.isNegative())
                                {
                                    return;
                                }

                                if (to_approve_in_value.isGreaterThan(approve_data.balance))
                                {
                                    // Если хотят одобрить больше чем есть баланса - не разрешаем. Стандарт ERC20 это
                                    // позволяет, но мы - нет. Ибо незачем. Выходим оставив выключенной кнопку Approve
                                    $("#approve-dialog-popup-content-info-red").text(
                                        helpers.sprintf(
                                            window.translations.approveDialog.balanceLessErrorMsg,
                                            approve_data.balance_formatted,
                                            approve_data.symbol,
                                        )).show()
                                    return;
                                }

                                // Зде все хорошо с введенными значениями. Включаем кнопку и прячем сообщение
                                // о недостаточности баланса
                                $("#approve-dialog-popup-content-info-red").hide();
                                this.buttons.approve.enable();
                            });

                        //Если передано to_approve_value - подставляем его в
                        if (approve_data.to_approve_value)
                        {
                            $("#approve-dialog-input-approve-value").val(approve_data.to_approve_value);
                            $("#approve-dialog-input-approve-value").keyup();
                        }
                    }

                    //FAF. Запускаем так, чтобы пользователь сразу увидел окно. Когда данные
                    //подгрузятся - интерфейс обновится. Исчезнет крутилка итд
                    async_activator();
                }
            });
        });
    }
}