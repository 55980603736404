import 'jquery-confirm';
import $ from 'jquery';
import helpers from "./helpers";
import {Web3Provider} from './web3provider';

import logger_module from "./logger";
const logger = logger_module.getLogObjcect().getLogger("popup");



export default {
    error: function (title = 'Error!', content = '', callback = () => {})
    {
        if (content.length > 200)
        {
            content = content.substr(0, 200);
        }
        return $.alert({
            title: title,
            content: content,
            type: 'red',
            animation: 'none',
            buttons: {
                ok: {
                    text: "OK",
                    action: () => {
                        callback();
                    }
                }
            }
        });
    },

    success: function (title = 'Success', content = '')
    {
        return $.alert({
            title: title,
            content: content,
            type: 'green',
            animation: 'none',
        });
    },



    green:function (title, content)
    {
        return $.alert({
            title: title,
            content: content,
            type: 'green',
            animation: 'none',
            buttons: {
                ok:{
                    isHidden: true,
                }
            },
        });
    },

    cancelable:function (title, content, cancel_btn, cancel_callback)
    {
        return $.alert({
            title: title,
            content: content,
            type: 'green',
            animation: 'none',
            buttons: {
                ok:{
                    text: cancel_btn,
                    isHidden: false,
                    action: () => {
                        cancel_callback();
                        return true;
                    }
                }
            },
        });
    },


    // waitAndSubmitTransactionPopup:async function (
    //     transaction_promise_function,
    //     callback_success,
    //     callback_error)
    // {
    //     const tr_popup = this.green(
    //         window.translations.popup.wait,
    //         window.translations.popup.waitingTransactionToBeSubmitted);
    //
    //     try {
    //         const transaction_promise =  await transaction_promise_function();
    //         tr_popup.setContent(window.translations.popup.waitingTransactionToBeMined);
    //         await transaction_promise();
    //         tr_popup.close();
    //         if (callback_success !== undefined) {
    //             callback_success();
    //         }
    //     }
    //     catch (e)
    //     {
    //
    //         setTimeout(tr_popup.close.bind(tr_popup), 100);
    //         if (callback_error !== undefined) {
    //             callback_error();
    //         } else
    //         {
    //             this.error(undefined, e.message);
    //         }
    //     }
    // },

    video: function (videoId)
    {
        let content = `<iframe width="100%" height="600" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`;
        $.dialog({
            title: '',
            content: content,
            theme: 'dark',
            backgroundDismiss: true,
            columnClass: 'col-md-12'
        });
    }
};