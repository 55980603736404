import helpers from './helpers';
import Web3 from 'web3';
import detectEthereumProvider from "@metamask/detect-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import {ProviderDialogs} from "./dialogs";
import popup from './popup';

import logger_module from './logger'
const logger = logger_module.getLogObjcect().getLogger("Web3Provider");

export class Web3Provider
{
    static #provider_type_cookie_key = "provider_type";
    static #cached_address_cookie_key = "cached_address";

    //Получение закэшиованного адреса из кукоа
    static getCachedAddress()
    {
        return localStorage.getItem(this.#cached_address_cookie_key);
    }

    //Стирание куки закэшированного ранее полученного адреса
    static #clearCachedAddress()
    {
        localStorage.removeItem(this.#cached_address_cookie_key);
    }

    //Стирание куки закэшированного ранее полученного адреса
    static #setCachedAddress(address)
    {
        localStorage.setItem(this.#cached_address_cookie_key, address);
    }

    //Получение типа провайдера из куков
    static getProviderType()
    {
        return localStorage.getItem(this.#provider_type_cookie_key);
    }

    //Стирание куки типа провайдера
    static #clearProviderType()
    {
        localStorage.removeItem(this.#provider_type_cookie_key);
    }

    //Метод инициализации web3 провайдера. Должен вызываться на всех страницах, где необходим провайдер
    static async initializeProvider(
        requested_account=null,
        back_path=null
    )
    {
        while (true)
        {
            //Получаем тип провайдера
            let provider_type = this.getProviderType();
            if (provider_type === null) //Если в localStorage нет типа провайдера - просим пользователя его задать
            {
                let should_go_back = false;

                //На всякий случай удаляем закэшированный адрес
                this.#clearCachedAddress();

                //Результатом выполнения будет или тип провайдера, или пользователь уйдет на другую страницу
                await ProviderDialogs.showSelectProviderDialog(
                    async () => {
                        const value = $('input[name="select-provider-radio"]:checked').val();
                        provider_type = value;
                    },
                    async () => {
                        provider_type = null;
                        should_go_back = true;
                    }
                );

                //Если здесь тип провайдера === null, значит была нажата кнопка back. Выходим из функции
                if (provider_type === null) {
                    if (back_path)
                    {
                        window.location = back_path;
                    } else
                    {
                        helpers.goBackOrHome();
                    }
                    return false;
                }

                //Здесь все хорошо - запоминаем установленный провайдер
                this.#setProviderType(provider_type)
            }

            //Инжектим провайдер
            if (!await this.#injectProviderAndWeb3(provider_type)) {
                let should_select_provider = false;
                let should_reload = false;
                switch (provider_type) {
                    case "metamask":
                        await ProviderDialogs.showInstallMetamaskDialog(
                            async () => {
                                should_reload = true;
                            },
                            async () => {
                                should_select_provider = true;
                            },
                        );
                        break;
                    case "walletconnect":
                        //По сути это происходит когда нажата кнопка "закрыть". Может еще есть ситуации, хз
                        //Просим еще раз выбрать провайдера
                        should_select_provider = true;
                        break;
                }

                //Возвращаемся к выбору провайдера
                if (should_select_provider) {
                    await this.#forgetProvider();
                    continue;
                }

                //Если была нажата кнопка ок, то делаем реловад и выходим из функции
                if (should_reload)
                {
                    window.location.reload();
                    return false;
                }
            }

            //Для metamask - проверка разблокированности аккаунта
            if (provider_type === "metamask")
            {
                let requested_accounts = null;
                let should_select_provider = false;
                let should_reload = false;
                let should_go_back = false;

                const expose_accounts_window = async () => {
                    //Отображение окна разблокировки Metamask. Логика работы следующая. Ниже вызывается конкурентно
                    //два промиса - expose_accounts_window и get_requested_accounts. Второй может либо заполнить
                    //значение переменной requested_accounts, либо зависнуть на методе eth_requestAccounts, либо
                    //бросить исключение, если ранее уже был вызван метод eth_requestAccounts, и теперь Metamask
                    //находится в ожидании разблокировки.
                    //Поэтому, ждем некоторый таймаут, проверяем requested_accounts, и если он не был заполнен
                    //в промисе get_requested_accounts, отображаем окно напоминания разблокировки Metamask

                    const timeout_ms = 1000;
                    await helpers.wait(timeout_ms);

                    //Если после таймаута не были получены аккаунты - показываем окошко
                    if (requested_accounts === null) {
                        //Чистим закэшированный адрес, т.к. сейчас у нас нет доступа к MM
                        this.#clearCachedAddress();

                        await ProviderDialogs.showExposeMetamaskAccountsDialog(
                            async () => {
                                should_reload = true;
                            },
                            async () => {
                                should_select_provider = true;
                            },
                            async () => {
                                should_go_back = true;
                            }
                        );
                    }
                }

                const get_requested_accounts = async () => {
                    // Промис запроса аккаунтов из метамаска. Если ранее запросов eth_requestAccounts не было,
                    // то поток выполнения будет оставаться на window.provider.request({method: 'eth_requestAccounts'})
                    //до тех пор, пока пользователь не разблочит акк. Если такой запрос будет подан повторно (например
                    //после перезагрузки страницы), eth_requestAccounts бросит исключение с кодом 32002
                    try {
                        requested_accounts = await window.provider.request({method: 'eth_requestAccounts'});
                    } catch (e) {
                        if (e.code === (-32002))
                        {
                            //Здесь пользователю уже показано окно о том, что необходимо разблокировать Metamask, но он
                            //все еще заблокирован. Если пользователь в окне нажмет ОК, получит перезагрузку страницы.
                            //Если разблокирует метамаск - получит перезагрузку страницы.

                        } else
                        {
                            logger.error(`Not expected exception happened e=${e.message}`)
                        }
                        //Обязательно нужно бросить исключение, чтобы корректно сработал Promise.any()
                        throw e;
                    }
                }

                // Ожидаем конкурентного завершения одного из промисов
                const promise_array = [
                    get_requested_accounts(),
                    expose_accounts_window(),
                ];
                await Promise.any(promise_array);


                //Возвращаемся к выбору провайдера
                if (should_select_provider) {
                    await this.#forgetProvider();
                    continue;
                }

                // Переходим "назад"
                if (should_go_back)
                {
                    if (back_path)
                    {
                        window.location = back_path;
                    } else
                    {
                        helpers.goBackOrHome();
                    }
                    return false
                }

                // Перезагружаем страницу
                if (should_reload)
                {
                    window.location.reload();
                    return false
                }

                if (requested_accounts === null || requested_accounts.length === 0)
                {
                    logger.error("Something gone wrong. Was unable to get metamask accounts");
                    return false;
                }
            }

            const chain_id = await window.web3.eth.getChainId();
            if (Number(CHAIN_ID) !== Number(chain_id))
            {
                let should_reload = false;
                let should_go_back = false;

                //Чистим закэшированный адрес, т.к. после переключения сети получим его снова
                this.#clearCachedAddress();

                switch (provider_type) {
                    case "metamask":
                        await ProviderDialogs.showChangeNetworkMetamaskDialog(
                            async ()=>{
                                should_reload = true;
                            },
                            async ()=>{
                                should_go_back = true;
                            }
                        );
                        break;
                    case "walletconnect":
                        await ProviderDialogs.showChangeNetworkWalletconnectDialog(
                            async ()=>{
                                should_reload = true;
                            },
                            async ()=>{
                                should_go_back = true;
                            }
                        );
                        break;
                }

                // Переходим "назад"
                if (should_go_back)
                {
                    if (back_path)
                    {
                        window.location = back_path;
                    } else
                    {
                        helpers.goBackOrHome();
                    }
                    return false
                }

                // Перезагружаем страницу
                if (should_reload)
                {
                    window.location.reload();
                    return false
                }

                logger.error("Something gone wrong. Should not be here 1")
                return false;
            }

            //Если включен не тот адрес, который на самом деле является owner-ом, то просим переключить аккаунт
            const account = await this.getDefaultAccount() // За одно адрес помещается в кэш, так нужно
            if (requested_account && account !== requested_account)
            {
                let should_select_provider = false;
                let should_reload = false;
                let should_go_back = false;

                switch (Web3Provider.getProviderType())
                {
                    case "walletconnect":
                        await ProviderDialogs.showChangeAccountWalletconnectDialog(
                            requested_account,
                            account,
                            async ()=>{
                                should_reload = true;
                            },
                            async () => {
                                should_go_back = true;
                            },
                            async () => {
                                await Web3Provider.#forgetProvider();
                                should_select_provider=true;
                            },
                        )
                        break;
                    case "metamask":
                    default:
                        await ProviderDialogs.showChangeAccountMetamaskDialog(
                            requested_account,
                            account,
                            async ()=>{
                                should_reload = true;
                            },
                            async () => {
                                should_go_back = true;
                            },
                            async () => {
                                should_select_provider=true;
                            },
                        )
                        break;
                }

                //Возвращаемся к выбору провайдера
                if (should_select_provider) {
                    await this.#forgetProvider();
                    continue;
                }

                // Переходим "назад"
                if (should_go_back)
                {
                    if (back_path)
                    {
                        window.location = back_path;
                    } else
                    {
                        helpers.goBackOrHome();
                    }
                    return false
                }

                // Перезагружаем страницу
                if (should_reload)
                {
                    window.location.reload();
                    return false
                }

                logger.error("Something gone wrong. Should not be here 2")
                return false
            }

            //Заканчиваем цикл while
            break;
        }

        return true;
    };

    //Метод установки типа провайдера. Может вызываться как перед методом injectProviderAndWeb3, так и внутри этого метода
    static #setProviderType(provider_type)
    {
        const allowed_provider_types = ["metamask", "walletconnect"];
        if (!allowed_provider_types.includes(provider_type))
        {
            throw new Error(`Wrong provider type ${provider_type}. Allowed types are ${JSON.stringify(allowed_provider_types)}`)
        }
        return localStorage.setItem(this.#provider_type_cookie_key, provider_type);
    }

    //Метод получения адреса из кошелька
    static async getDefaultAccount() {
        //Если провайдер не установлен - даже не пытаемся достать аккаунты
        if (!this.isProviderInjected())
        {
            //Чистим закэшированный адрес на всякий случай
            this.#clearCachedAddress();

            throw new Error("Uninitialized provider");
        }

        let accounts;
        switch (this.getProviderType())
        {
            case "metamask":
                // Пробуем получить аккаунты. Такой запрос форсирует запрос на подключение MetaMask-а через его UI
                accounts = await window.provider.request({method: 'eth_requestAccounts'});
                break;
            case "walletconnect":
                accounts = await window.web3.eth.getAccounts();
                break;
            default:
                break;
        }

        if (accounts.length !== 0) {
            logger.debug(`Main account ${accounts[0]} got`);
            const checksum_address = Web3.utils.toChecksumAddress(accounts[0]);
            this.#setCachedAddress(checksum_address);
            return checksum_address;
        } else {
            //Чистим закэшированный адрес, т.к. здесь мы не имеет доступа к адресу
            this.#clearCachedAddress();

            logger.debug("User denied access to provider");
            return null;
        }
    }


    //Метод забывания (насколько это возможно отключения) провайдера
    static async #forgetProvider()
    {
        switch (this.getProviderType())
        {
            case "walletconnect":
                localStorage.removeItem("walletconnect");
                break;
            case "metamask":
            default:
                // Здесь ничего не нужно делать
                break;
        }

        window.web3 = null;
        window.provider = null;

        this.#clearProviderType();
        this.#clearCachedAddress();
    }

    //Проверка того, что провайдер установлен
    static isProviderInjected()
    {
        return (
            window.provider
            && window.web3
            //Проверяем куку, т.к. при отключении провайдера Metamask не отключится сам. Поэтому так имитируем отключение для MM
            && this.getProviderType()
        );
    }

    //Метод отключения провайдера и стирание его данных из куков
    static async disconnectAndForgetProvider()
    {
        if (this.isProviderInjected())
        {
            switch (this.getProviderType())
            {
                case "metamask":
                    await ProviderDialogs.showWarnMetamaskDisconnectDialog();
                    break;
                case "walletconnect":
                default:
                    try {
                        await window.provider.disconnect();
                    } catch (e) {
                        logger.error(`window.provider.disconnect failed=${e.message}`);
                    }
                    break;
            }
        }

        await this.#forgetProvider(true);
    }

    // Комплексная проверка доступности провайдера и аккаунта
    static async isProviderReady()
    {
        return this.isProviderInjected() && (await this.getDefaultAccount());
    }

    //Функция инициализации провайдера и web3. Должна вызываться на всех страницах, где нужен
    //провайдер и запись в блокчейн. Инжектится в window.provider и window.web3. Если все ок
    //возвращает true, иначе - false.
    //Если передан тип провайдера - используется он. Если нет - пытаемся достать тип из куков
    static async #injectProviderAndWeb3(provider_type)
    {
        let provider = null;
        switch (provider_type)
        {
            case "metamask":
                // Метод detectEthereumProvider не находит WalletConnect провайдера и возвращает null
                provider = await detectEthereumProvider({
                    mustBeMetaMask: false,
                    silent: false,
                    timeout: 1000
                });
                break;
            case "walletconnect":
                const rpc_map = {}
                rpc_map[CHAIN_ID] = RPC_WEB3
                try {
                    provider = await EthereumProvider.init({
                        projectId: WC_PROJECT_ID, // FIXME: убрать отсюда, по идее
                        chains: [CHAIN_ID],
                        showQrModal: true, // requires @walletconnect/modal,
                        methods: [
                            "eth_sendTransaction",
                            "eth_signTransaction",
                            "eth_sign",
                            "personal_sign",
                            "eth_signTypedData",
                        ],
                        rpcMap: rpc_map
                    })
                    await provider.enable();
                } catch (e)
                {
                    logger.error(`Error initiating WalletConnect provider. Exception=${e}`)

                    //Делаем так, чтобы обработка прошла так, будто провайдера нет.
                    provider = null;
                }
                break;
            default:
                logger.error(`Wrong provider type=${provider_type}. Stack=${new Error().stack}`)
        }

        // Настраиваем провайдера
        if (provider !== null)
        {
            provider.autoRefreshOnNetworkChange = false;

            provider.on('connect', () => {
                logger.debug("Provider connect event");
                this.#clearCachedAddress();
                // window.location.reload();
            });

            provider.on('disconnect', () => {
                logger.debug("Provider disconnect event");
                this.#forgetProvider();
                window.location.reload();
            });

            provider.on('chainChanged', (chainId) => {
                logger.debug(`chainChanged. Current chain id=${chainId}`);
                window.location.reload();
            });
            provider.on('accountsChanged', (accounts) => {
                logger.debug(`accountsChanged. Available accounts=${JSON.stringify(accounts)}`);
                this.#clearCachedAddress();
                window.location.reload();
            });

            window.provider = provider
            window.web3 = new Web3(window.provider);

            return true;
        }
        else
        {
            logger.error(`Web3 provider of type=${provider_type} was not initialized. Stack:\n${new Error().stack}`)
            return false;
        }
    }

    //Функция проверки, не бросила ли исключение транзакция
    static async didTransactionThrow(txid) {
        const receipt = await window.web3.eth.getTransactionReceipt(txid);
        if (receipt.status === false) {
            return {
                didThrown: true,
                txid: txid,
                message: "Transaction " + txid + " DID throw"
            };
        }
        return {
            didThrown: false,
            txid: txid,
            message: "Transaction " + txid + " did  NOT throw"
        };
    }

    static async didTransactionThrowShort(txid) {
        const receipt = await window.web3.eth.getTransactionReceipt(txid);
        return receipt.status === false;
    }
    //Функция для ожидания выполнения транзакции
    //Имеет смысл при вызове с await
    static async waitTransaction(tx_hash)
    {
        while (true)
        {
            const data = await window.web3.eth.getTransaction(tx_hash);
            if (data !== null && data.blockNumber !== null)
            {
                logger.debug("Transaction data=%s", JSON.stringify(data, null, 2));
                return data;
            }
            await helpers.wait(500);
        }
    }

    //Функция для ожидания выполнения транзакции
    //и приемения callback после выполнение. Задумано использование
    //без await, для того чтобы не ждать транзу, а потом ее обработать
    //по факту выполнения
    static async processTransaction (tx_hash, callback)
    {
        while (true)
        {
            let data = await window.web3.eth.getTransaction(tx_hash);
            if (data !== null && data.blockNumber !== null)
            {
                logger.debug("Transaction data=%s", JSON.stringify(data, null, 2));
                callback(data['hash']);
                break;
            }
            await helpers.wait(1000);
        }
    }

    //Возвращает промис замайнивания транзакции когда та уже отправлена в сеть
    static async getWaitTransactionPromise(tx_hash)
    {
        return async () => {
            await this.waitTransaction(tx_hash);
            await this.didTransactionThrow(tx_hash);
        }
    }


    static async submitTransactionPopup(
        not_executed_transaction_promise,
        callback_success = null,
        callback_error = null,

    )
    {
        //Метод для подписи транзакции. Только подписи, без замайнивания. Возвращает
        //либо tx_id если все хорошо, либо null, если по каким-то причинам транза не была
        //подписана (пользователь отказался от подписи, произошла ошибка). Обработка ошибок
        //производится здесь, на месте. Метод не бросает исключений !. Если переданы колбэки,
        //вызывает их, либо с tx_id если успех, либо с объектом исключения. Если транзакция
        //отменена пользователем, callback_error не вызывается
        const confirm_popup  = popup.green(
            window.translations.popup.wait,
            window.translations.popup.waitingTransactionToBeSubmitted);

        let tx_id = null;
        try {
            tx_id =  await not_executed_transaction_promise();
            confirm_popup.close();
        } catch (e) {
            confirm_popup.close();
            if ([4001, 5000].includes(Number(e.code))) {
                //Отказ подтверждения транзакции пользователем. Коды для Metamask и Walletconnect
                //Никакие колбэки здесь не дергаем
                return null;
            }
            logger.error(`Error confirming transaction. E=${e.message}`);

            if (callback_error)
            {
                //Если передан колбэк - дергаем его
                await callback_error(e);
            } else
            {
                //Если не передан - показываем сообщение об ошибке
                popup.error(window.translations.popup.errorTitle, e.message);
            }
            return null;
        }

        if (callback_success !== null)
        {
            await callback_success(tx_id);
        }
        return tx_id;
    };


    static async submitTransactionAndWaitToBeMinedPopup(
        not_executed_transaction_promise,
        callback_success = null,
        callback_error = null,
    )
    {
        //Метод для подписи транзакции и ожидания ее замайнивания.  Возвращает
        //либо tx_id если все хорошо, либо null, если по каким-то причинам транза не была
        //подписана (пользователь отказался от подписи, произошла ошибка). Обработка ошибок
        //производится здесь, на месте. Метод не бросает исключений !. Если переданы колбэки,
        //вызывает их, либо с tx_id если успех, либо с объектом исключения. Если транзакция
        //отменена пользователем, callback_error не вызывается
        const confirm_popup  = popup.green(
            window.translations.popup.wait,
            window.translations.popup.waitingTransactionToBeSubmitted);

        let tx_id = null;
        try {
            tx_id =  await not_executed_transaction_promise();

            //Меняем подпись на инфо окне
            confirm_popup.setContent(window.translations.popup.waitingTransactionToBeMined);

            //Ждем замайнивания
            await Web3Provider.waitTransaction(tx_id);

            //Проверяем, не бросила ли транзакция исключение
            if (await Web3Provider.didTransactionThrowShort(tx_id))
            {
                //Отправляем отчет об ошибке
                if (window.tclient !== undefined)
                {
                    //Отправляем отчет об ошибке в traceo
                    window.tclient.report_error(
                        "Transaction thrown",
                        {
                            "txid": tx_id,
                        })
                }

                throw new Error(`Transaction tx_id=${tx_id} did thrown`);
            }
            confirm_popup.close();
        } catch (e) {
            confirm_popup.close();
            if ([4001, 5000].includes(Number(e.code))) {
                //Отказ подтверждения транзакции пользователем. Коды для Metamask и Walletconnect
                //Никакие колбэки здесь не дергаем
                return null;
            }
            logger.error(`Error confirming transaction. E=${e.message}`);

            if (callback_error)
            {
                //Если передан колбэк - дергаем его
                await callback_error(e);
            } else
            {
                //Если не передан - показываем сообщение об ошибке
                popup.error(window.translations.popup.errorTitle, e.message);

            }
            return null;
        }

        if (callback_success !== null)
        {
            await callback_success(tx_id);
        }
        return tx_id;
    }
}
