'use strict';

const log = require('loglevel');
const prefix = require('loglevel-plugin-prefix');

let inited = false;

export default {

    getLogObjcect()
    {
        if (!inited)
        {
            prefix.reg(log);
            log.enableAll();

            prefix.apply(log, {
              format(level, name, timestamp) {
                  return `[${timestamp}] ${level} ${name}:`;
              },
            });


            inited = true;
        }

        return log;
    }
}